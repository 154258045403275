import React, { Fragment } from "react";
import { Field, change } from "redux-form";
import PropTypes from "prop-types";
import {
  GBLI_EMAIL_EVENTS,
  GBLI_PHONE_EVENTS_TITLE,
} from "@gbli-events/common/src/Constants/shared";
import { connect, useSelector } from "react-redux";
import EventCard from "src/Components/EventSelection/EventCard";
import {
  requiredEvent,
  selectionRequired,
} from "@gbli-events/common/src/Helpers/validators";
import WeddingImage from "@gbli-events/common/src/Assets/Images/wedding-md.jpg";
import DonationImage from "@gbli-events/common/src/Assets/Images/donation-md.jpg";
import BirthdayCandlesImage from "@gbli-events/common/src/Assets/Images/birthday-candles-md.jpg";
import BabyShowerImage from "@gbli-events/common/src/Assets/Images/baby-shower-md.jpg";
import QuiceaneraImage from "@gbli-events/common/src/Assets/Images/quiceanera-md.jpg";
import IndividualVendorImage from "@gbli-events/common/src/Assets/Images/individual-vendor-md.jpg";
import AssociationOrClubMeeting from "@gbli-events/common/src/Assets/Images/association-or-club-meeting.jpg";
import AwardShow from "@gbli-events/common/src/Assets/Images/award-show.jpg";
import BlockParty from "@gbli-events/common/src/Assets/Images/block-party.jpg";
import Picnic from "@gbli-events/common/src/Assets/Images/picnic.jpg";
import Convention from "@gbli-events/common/src/Assets/Images/convention.jpg";
import GalaOrBall from "@gbli-events/common/src/Assets/Images/gala-or-ball.jpg";
import CelebrationOfife from "@gbli-events/common/src/Assets/Images/celebration-of-life.jpg";
import BusinessMeeting from "@gbli-events/common/src/Assets/Images/business-meeting.jpg";
import BarBatMitzvahParty from "@gbli-events/common/src/Assets/Images/bar-bat-mitzvah-party.jpg";
import GolfTournament from "@gbli-events/common/src/Assets/Images/golf-outing-tournament.jpg";
import RadioFieldGroup from "@gbli-events/common/src/Components/FormElements/RadioFieldGroup/RadioFieldGroup";
import Alert from "@gbli-events/common/src/Components/Themed/Alert";
import ThemeColor from "@gbli-events/common/src/Components/Themed/ThemeColor";
import EventTypeSelect from "src/Components/EventSelection/EventTypeSelect";

import {
  eventRiskClass3Error,
  eventRiskClass9Error,
  isEventSelectionPageValid,
  venueEventSelectionHasErrors,
  getCannabisErrors,
} from "src/Selectors/Event";
import { getMainVenueStateName, isVenuePageValid } from "src/Selectors/Venue";
import { getTheme, getThemedSelectStyles } from "src/Selectors/Shared";
import { eventPropType } from "src/Models/EventTypeModel";
import NavContinueButton from "src/Components/NavContinueButton";
import { formName } from "src/Constants/constants";
import { confirmVenueEventForm } from "src/Actions/events";
import { getUserEntryDataPayload } from "src/Selectors/UserEntryData";
import { saveUserEntryData } from "src/Actions/userEntryData";

const mapStateToProps = (state) => {
  const { cannabisError, venueStateCannabisErrorMessage } =
    getCannabisErrors(state);
  return {
    eventTypes: state.formPage.eventTypes,
    selectedEventType: state.form.application.values.eventType,
    cannabis: state.form.application.values.cannabis,
    cannabisError: cannabisError,
    eventRiskClass3Error: eventRiskClass3Error(state),
    eventRiskClass9Error: eventRiskClass9Error(state),
    formHasErrors: venueEventSelectionHasErrors(state),
    isPageValid: isVenuePageValid(state) && isEventSelectionPageValid(state),
    venueStateCannabisErrorMessage: venueStateCannabisErrorMessage,
    mainVenueStateName: getMainVenueStateName(state),
    sortedEventTypes: state.formPage.venue.eventTypes,
    cannabisCheckLoading: state.formPage.eventType?.isValidationInProgress,
  };
};

const mapDispatchToProps = (dispatch) => ({
  selectEventType: (eventType) =>
    dispatch(
      change(formName, "eventType", {
        label: eventType.name,
        value: eventType,
      })
    ),
  confirmVenueAndEventSelection: () => dispatch(confirmVenueEventForm()),
});

const propTypes = {
  eventTypes: PropTypes.arrayOf(eventPropType).isRequired,
  selectedEventType: eventPropType,
  cannabis: PropTypes.string,
  cannabisError: PropTypes.bool.isRequired,
  eventRiskClass3Error: PropTypes.bool.isRequired,
  eventRiskClass9Error: PropTypes.bool.isRequired,
  formHasErrors: PropTypes.bool.isRequired,
  isPageValid: PropTypes.bool.isRequired,
  selectEventType: PropTypes.func.isRequired,
  confirmVenueAndEventSelection: PropTypes.func.isRequired,
  venueStateCannabisErrorMessage: PropTypes.string.isRequired,
  mainVenueStateName: PropTypes.string,
  sortedEventTypes: PropTypes.arrayOf(
    PropTypes.shape({
      identifier: PropTypes.string.isRequired,
    })
  ),
  cannabisCheckLoading: PropTypes.bool.isRequired,
};

const EventCards = ({
  eventTypes,
  selectEventType,
  selectedEventType,
  sortedEventTypes,
}) => {
  const eventCards = [
    {
      identifier: "wedding-ceremony-reception",
      cardTitle: "Wedding Ceremony & Reception",
      image: WeddingImage,
    },
    {
      identifier: "birthday-party-no-admission-charge-invite-only",
      cardTitle: "Birthday Party",
      image: BirthdayCandlesImage,
    },
    {
      identifier: "quinceanera-no-admission-charge-invite-only",
      cardTitle: "Quinceanera",
      image: QuiceaneraImage,
    },
    {
      identifier: "charity-party-dance-dinner-lunch",
      cardTitle: "Charity Party, Dance, Dinner or Lunch",
      image: DonationImage,
    },
    {
      identifier: "baby-or-wedding-shower",
      cardTitle: "Wedding or Baby Shower",
      image: BabyShowerImage,
    },
    {
      identifier: "individual-vendor",
      cardTitle: "Individual Vendor",
      image: IndividualVendorImage,
    },
    {
      identifier: "association-or-club-meeting-not-a-convention-or-trade-show",
      cardTitle: "Association or Club Meeting",
      image: AssociationOrClubMeeting,
    },
    {
      identifier: "award-show",
      cardTitle: "Award Show",
      image: AwardShow,
    },
    {
      identifier: "barbat-mitzvah-party-no-admission-charge-invite-only",
      cardTitle: "Bar/Bat Mitzvah Party",
      image: BarBatMitzvahParty,
    },
    {
      identifier: "block-party-no-admission-charge-invite-only",
      cardTitle: "Block Party",
      image: BlockParty,
    },
    {
      identifier: "celebration-of-life-no-admission-charge-invite-only",
      cardTitle: "Celebration of Life",
      image: CelebrationOfife,
    },
    {
      identifier: "gala-or-ball",
      cardTitle: "Gala or Ball",
      image: GalaOrBall,
    },
    {
      identifier: "picnic",
      cardTitle: "Picnic",
      image: Picnic,
    },
    {
      identifier: "convention",
      cardTitle: "Convention",
      image: Convention,
    },
    {
      identifier: "business-meeting-not-a-convention-or-trade-show",
      cardTitle: "Business Meeting",
      image: BusinessMeeting,
    },
    {
      identifier: "golf-outingtournament",
      cardTitle: "Golf Outing/Tournament",
      image: GolfTournament,
    },
  ];

  if (sortedEventTypes?.length > 0) {
    const identifierIndexMap = new Map();
    sortedEventTypes.forEach((item, index) => {
      identifierIndexMap.set(item.identifier, index);
    });

    eventCards.sort((a, b) => {
      const indexA = identifierIndexMap.get(a.identifier);
      const indexB = identifierIndexMap.get(b.identifier);
      return indexA - indexB;
    });
  }

  return eventCards.slice(0, 6).map(({ identifier, image, cardTitle }) => {
    const eventType = eventTypes.find(
      (eventType) => identifier === eventType.identifier
    );

    return eventType ? (
      <div key={identifier} className="col-12 col-lg-4">
        <EventCard
          eventType={eventType}
          cardTitle={cardTitle}
          image={image}
          selected={selectedEventType?.value?.identifier === identifier}
          onClick={() => selectEventType(eventType)}
        />
      </div>
    ) : (
      <Fragment key={identifier} />
    );
  });
};

const EventSelection = ({
  eventTypes,
  selectEventType,
  selectedEventType,
  cannabisError,
  eventRiskClass3Error,
  eventRiskClass9Error,
  formHasErrors,
  isPageValid,
  venueStateCannabisErrorMessage,
  mainVenueStateName,
  confirmVenueAndEventSelection,
  sortedEventTypes,
  cannabisCheckLoading,
}) => {
  const selectOptions = eventTypes.map((eventType) => ({
    label: eventType.name,
    value: eventType,
  }));

  const theme = useSelector(getTheme);
  const selectStyles = useSelector(getThemedSelectStyles);
  const userEntryData = useSelector(getUserEntryDataPayload);

  return (
    <div className="event-selection">
      <div className="container mb-4">
        <div className="page-heading event-selection__page-heading">
          <h2>
            <ThemeColor variant="secondary">
              Choose the type of event you need to insure.
            </ThemeColor>
          </h2>
          <p className="text-center font-weight-bold mt-2">
            Coverage for Fairs, Festivals, Sporting Events, Concerts,
            Fundraisers, and More!
          </p>
        </div>
        <div className="event-selection__card-container">
          {eventRiskClass3Error && (
            <Alert variant="error" className="mt-3">
              <i className="far fa-exclamation-circle alert__icon" />
              <div className="alert__text">
                Sorry, the event type you have selected cannot be bound online.
                Please contact us at{" "}
                <span className="text-nowrap">{GBLI_PHONE_EVENTS_TITLE}</span>{" "}
                or {GBLI_EMAIL_EVENTS} for further assistance.
              </div>
            </Alert>
          )}
          {eventRiskClass9Error && (
            <Alert variant="error" className="mt-3">
              <i className="far fa-exclamation-circle alert__icon" />
              <div className="alert__text">
                Sorry, we can't underwrite this event type. Please contact your
                local agent or broker for further assistance.
              </div>
            </Alert>
          )}
          <Field
            name="eventType"
            options={selectOptions}
            styles={selectStyles}
            theme={theme}
            validate={requiredEvent}
            component={EventTypeSelect}
          />
          <div className="row">
            <EventCards
              eventTypes={eventTypes}
              selectEventType={selectEventType}
              selectedEventType={selectedEventType}
              sortedEventTypes={sortedEventTypes}
            />
          </div>
          {!cannabisCheckLoading &&
            cannabisError &&
            !venueStateCannabisErrorMessage.length && (
              <Alert variant="error" className="mt-3">
                <i className="far fa-exclamation-circle alert__icon" />
                <div className="alert__text">
                  Sorry, the event type you have selected cannot be bound online
                  with cannabis coverage. Please contact us at{" "}
                  <span className="text-nowrap">{GBLI_PHONE_EVENTS_TITLE}</span>{" "}
                  or {GBLI_EMAIL_EVENTS} for further assistance.
                </div>
              </Alert>
            )}
          {!cannabisCheckLoading &&
            venueStateCannabisErrorMessage.length > 0 && (
              <Alert variant="error" className="mt-3">
                <i className="far fa-exclamation-circle alert__icon" />
                <div className="alert__text">
                  {venueStateCannabisErrorMessage}
                </div>
              </Alert>
            )}
          <div className="event-selection__cannabis">
            <p className="text-center mb-4 mb-lg-2">
              Will cannabis or cannabis-related products be sampled, sold,
              displayed, distributed and/or consumed at this event?
            </p>
            <RadioFieldGroup
              className="text-center"
              controlName="cannabis"
              validators={[selectionRequired]}
              options={[
                { label: "Yes", value: "1" },
                { label: "No", value: "0" },
              ]}
            />
          </div>
        </div>

        {!cannabisCheckLoading && formHasErrors && (
          <Alert variant="danger">
            <i className="far fa-exclamation-circle alert__icon" />
            <div className="alert__text">Please correct the errors above.</div>
          </Alert>
        )}

        <NavContinueButton
          disabled={formHasErrors}
          onClick={() => {
            confirmVenueAndEventSelection();
            if (isPageValid) saveUserEntryData(userEntryData);

            return isPageValid;
          }}
        />
      </div>
    </div>
  );
};

EventSelection.propTypes = propTypes;

export default connect(mapStateToProps, mapDispatchToProps)(EventSelection);
